import React, { Component } from 'react';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
    Container,
    Grid,
    Paper,
    TextField,
    Button,
    Typography,
    Box,
    CircularProgress,
    Chip
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Toolbar from '@material-ui/core/Toolbar';
import Table from '@material-ui/core/Table';

import LastPageIcon from '@material-ui/icons/LastPage';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import DialogModal from '../../components/DialogModal/DialogModal';

import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

import VisibilityIcon from '@material-ui/icons/Visibility';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {
    FILE_STATUS,
    seriesColummList,
    filterFields
} from '../../../src/constants';

import _ from 'lodash';
import {
    fetchAllHealthSystems,
    fetchAllHealthSystemwiseSummaryCount,
    fetchAllRequests,
    downloadRADocumentUsingSignedURL,
    fetchJobSummary    
} from '../../components/CommonFunctions';

am4core.useTheme(am4themes_animated);
const moment = require("moment")
const style = {
    paper: {
        width: '100%'
    },
    landingPageButtonWarpper: {
        display: 'flex',
        justifyContent: "flex-end"
    },
    statusInqueue: {
        backgroundColor: 'lightgreen',
        color: "black",
        width: '100%'
    },
    statusInprogress: {
        backgroundColor: 'lightblue',
        color: "black",
        width: '100%',
    },
    statusPatientNotFound: {
        backgroundColor: '#ffa500',
        color: "black",
        width: '100%'
    },
    statusMultiplePatientsFound: {
        backgroundColor: '#800080',
        color: "white",
        width: '100%'
    },
    statusEncounterNotFound: {
        backgroundColor: '#ffff00',
        color: "black",
        width: '100%'
    },
    statusDocumentNotFound: {
        backgroundColor: '#A52A29',
        color: "white",
        width: '100%'
    },
    statusProcessed: {
        backgroundColor: '#008000',
        color: "white",
        width: '100%'
    },
    statusFailed: {
        backgroundColor: '#ff0000',
        color: "white",
        width: '100%'
    },
    statusFYIFlagFound: {
        backgroundColor: '#808080',
        color: "white",
        width: '100%'
    },
    patientMismatch: {
        backgroundColor: 'palevioletred',
        color: "white",
        width: '100%'
    }
};

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLoader: true,
            healthSystemList: [],
            selectedHealthSystem: null,
            requestList: [],
            totalRequestCount: null,
            rowsPerPage: 100,
            page: 0,
            jobSummaryList: [],
            totalJobSummaryCount: null,
            jobSummaryRowsPerPage: 5,
            jobSummaryPage: 0,
            showSortingLoader: false,
            showDialogModal: false,
            searchFields: filterFields
        }

        this.chart = null
    }

    async componentDidMount() {
        const healthSystemList = await fetchAllHealthSystems();
        await this.setState({
            healthSystemList: healthSystemList,
            selectedHealthSystem: healthSystemList.length ? healthSystemList[0] : null
        })

        await this.fetchAllRequestCountTemplate(healthSystemList && healthSystemList.length && healthSystemList[0].value ? healthSystemList[0].value : null);
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    fetchAllRequestCountTemplate = async (healthSystem) => {
        const {
            page,
            rowsPerPage
        } = _.cloneDeep(this.state)
       
        await this.setState({
            requestList: [],
            totalRequestCount: null,
            page: 0,
            rowsPerPage: 100
        })
        const summaryList = await fetchAllHealthSystemwiseSummaryCount(healthSystem)

        var chart = am4core.create("chartdiv", am4charts.PieChart);
        chart.seriesContainer.draggable = false;
        chart.logo.disabled = true
        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

        let finalData = []

        if (summaryList && summaryList.length > 0) {
            _.each(seriesColummList, function(d) {
                let findData = _.find(summaryList, { name: d.field })
                finalData.push(findData)
            })
        }

        chart.data = finalData
        chart.radius = am4core.percent(70);
        chart.innerRadius = am4core.percent(40);
        chart.startAngle = 180;
        chart.endAngle = 360;

        var series = chart.series.push(new am4charts.PieSeries());
        series.dataFields.value = "count";
        series.dataFields.category = "name";

        series.slices.template.cornerRadius = 10;
        series.slices.template.innerCornerRadius = 7;
        series.slices.template.draggable = false;
        series.slices.template.inert = true;
        series.alignLabels = true

        series.hiddenState.properties.startAngle = 90;
        series.hiddenState.properties.endAngle = 90;

        series.legendSettings.labelText = '{name}'
        series.legendSettings.valueText = '{count}'
        series.labels.template.text = ""

        let colorList = []
        _.each(seriesColummList, function(d) {
            colorList.push(new am4core.color(d.color))
        })
        series.colors.list = colorList

        var label = chart.seriesContainer.createChild(am4core.Label);
        label.textAlign = "middle";
        label.horizontalCenter = "middle";
        label.verticalCenter = "middle";
        label.adapter.add("text", function (text, target) {
            return "[font-size:18px]Total[/]:\n[bold font-size:30px]" + series.dataItem.values.value.sum + "[/]";
        })

        series.slices.template.events.on("hit", function (ev) {
            console.log("Event ::: ", ev.target.dataItem.category)
        })

        chart.legend = new am4charts.Legend();
        chart.legend.position = "right"
        chart.legend.valign = "top"
        chart.legend.maxWidth = '250px'

        var slice = series.slices.template;
        slice.propertyFields.fill = "color";
        slice.propertyFields.fillOpacity = "opacity";
        slice.propertyFields.stroke = "color";
        slice.propertyFields.strokeDasharray = "strokeDasharray";
        slice.propertyFields.tooltipText = "tooltip";

        await this.setState({
            showSortingLoader: true,
            showLoader: false
        })

        const requestResponse = await fetchAllRequests(healthSystem, null, page, rowsPerPage)

        await this.setState({
            requestList: (requestResponse && requestResponse.requestList && requestResponse.requestList.length) ? requestResponse.requestList : [],
            totalRequestCount: (requestResponse && requestResponse.totalRequests) ? requestResponse.totalRequests : null,
            showSortingLoader: false
        })
    }

    refreshChartButtonClickHandler = async () => {
        const { selectedHealthSystem } = _.cloneDeep(this.state)
        if (this.chart) {
            this.chart.dispose();
        }
        this.clearSearchFieldsHandler();
        await this.fetchAllRequestCountTemplate(selectedHealthSystem.value);
    }

    onHealthSystemChangeHandler = async (value) => {
        if (this.chart) {
            this.chart.dispose();
        }
        await this.setState({
            selectedHealthSystem: value
        })
        this.clearSearchFieldsHandler();
        await this.fetchAllRequestCountTemplate(value.value);
    }

    fetchSearchFieldsTemplate = (classes) => {
        const {
            searchFields
        } = _.cloneDeep(this.state)

        return (
            <React.Fragment>
                {
                    searchFields.map((row, index) => (
                        <Grid key={index} item xs={row.xs} md={row.md}>
                            {
                                row.type === "string" ? (
                                    <TextField
                                        label={row.label}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        name={row.id}
                                        onChange={e => this.handleSearchFieldChange(e, row.responseValue)}
                                        value={row.value}
                                        helperText={row.isError ? row.errorMessage : ""}
                                        error={row.isError}
                                        id={row.id}
                                        onKeyPress={e => this.handleSearchFieldKeyPress(e)}
                                        // disabled={row.isDisabled ? row.isDisabled : false}
                                    />
                                ) : row.type === "integer" ? (
                                    <TextField
                                        label={row.label}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        name={row.id}
                                        onChange={e => this.handleSearchFieldChange(e, row.responseValue)}
                                        value={row.value}
                                        helperText={row.isError ? row.errorMessage : ""}
                                                error={row.isError}
                                        id={row.id}
                                        // disabled={row.isDisabled ? row.isDisabled : false}
                                        type="number"
                                    />
                                ) : (row.type === 'select' && !row.isMultiple && row.isMultiple === false) ? (
                                    <Autocomplete
                                        options={row.options}
                                        value={row.value}
                                        getOptionLabel={({ label }) => label}
                                        getOptionSelected={(option, value) => option.value === value.value}
                                        disableClearable={false}
                                        blurOnSelect
                                        size='small'
                                        onChange={(event, value) => this.handleSearchFieldChange(value, row.responseValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={row.label}
                                                variant="outlined"
                                                helperText={row.isError ? row.errorMessage : ""}
                                                error={row.isError}
                                            />
                                        )}
                                        className="autocomplete-field"
                                        // disabled={row.isDisabled ? row.isDisabled : false}
                                    />
                                ) : (row.type === 'select' && row.isMultiple && row.isMultiple === true) ? (
                                    <Autocomplete
                                        multiple
                                        options={row.options}
                                        value={row.value}
                                        getOptionLabel={({ label }) => label}
                                        getOptionSelected={(option, value) => option.value === value.value}
                                        filterSelectedOptions
                                        disableClearable={false}
                                        blurOnSelect
                                        size='small'
                                        onChange={(event, value) => this.handleSearchFieldChange(value, row.responseValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={row.label}
                                                variant="outlined"
                                                helperText={row.isError ? row.errorMessage : ""}
                                                error={row.isError}
                                            />
                                        )}
                                        className="autocomplete-field"
                                        // disabled={row.isDisabled ? row.isDisabled : false}
                                    />
                                ) : row.type === 'date' ? (
                                    <TextField
                                        id={row.id}
                                        label={row.label}
                                        type="date"
                                        size="small"
                                        value={row.value}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                        onChange={(e, value) => this.handleSearchFieldChange(e, row.responseValue)}
                                        helperText={row.isError ? row.errorMessage : ""}
                                        error={row.isError}
                                        // disabled={row.isDisabled ? row.isDisabled : false}
                                    />
                                ) : row.type === 'datetime' ? (
                                    <TextField
                                        id={row.id}
                                        label={row.label}
                                        type="datetime-local"
                                        size="small"
                                        value={row.value}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                        onChange={(e, value) => this.handleSearchFieldChange(e, row.responseValue)}
                                        helperText={row.isError ? row.errorMessage : ""}
                                        error={row.isError}
                                        // disabled={row.isDisabled ? row.isDisabled : false}
                                    />
                                ) : null
                            }
                        </Grid>
                    ))
                }
            </React.Fragment>
        )
    }

    handleSearchFieldChange = async (e, fieldName) => {
        let { searchFields } = _.cloneDeep(this.state);
        const findFieldData = _.find(searchFields, { responseValue: fieldName });

        let fieldValue = "";
        if (findFieldData) {
            if (findFieldData.type === 'select') {
                fieldValue = e;
            } else {
                fieldValue = e.target.value;
            }
        }
        let findField = _.find(searchFields, { responseValue: fieldName });
        findField.value = fieldValue;        

        const selectedFromDate = _.find(searchFields, { responseValue: "fromdate" }).value
        const selectedToDate = _.find(searchFields, { responseValue: "todate" }).value
        let errors = {
            fromdate: {
                isError: false,
                errorMessage: ""
            },
            todate: {
                isError: false,
                errorMessage: ""
            }
        }

        if (moment(selectedFromDate).isAfter(moment())) {
            errors.fromdate.isError = true;
            errors.fromdate.errorMessage = "Date can not be in future."
        } else if (moment(selectedFromDate).isAfter(moment(selectedToDate))) {
            errors.fromdate.isError = true;
            errors.fromdate.errorMessage = "Date can not be greater than to date."
        } else {
            errors.fromdate.isError = false;
            errors.fromdate.errorMessage = ""
        }

        if (moment(selectedToDate).isAfter(moment())) {
            errors.todate.isError = true;
            errors.todate.errorMessage = "Date can not be in future."
        } else if (moment(selectedFromDate).isAfter(moment(selectedToDate))) {
            errors.todate.isError = true;
            errors.todate.errorMessage = "Date can not be less than from date."
        } else {
            errors.todate.isError = false;
            errors.todate.errorMessage = ""
        }

        let findFromDateField = _.find(searchFields, { responseValue: "fromdate" });
        findFromDateField.isError =  errors.fromdate.isError; 
        findFromDateField.errorMessage =  errors.fromdate.errorMessage;

        let findToDateField = _.find(searchFields, { responseValue: "todate" });
        findToDateField.isError =  errors.todate.isError; 
        findToDateField.errorMessage =  errors.todate.errorMessage; 

        await this.setState({ searchFields });
    }

    clearSearchFieldsHandler = async () => {
        const { 
            selectedHealthSystem,
            page,
            rowsPerPage
        } = _.cloneDeep(this.state)
        this.setState({
            searchFields: filterFields,
            requestList: [],
            totalRequestCount: null,
            showSortingLoader: true,
            page: 0,
            rowsPerPage: 100
        })
        const requestResponse = await fetchAllRequests(selectedHealthSystem.value, null, page, rowsPerPage)

        this.setState({
            requestList: (requestResponse && requestResponse.requestList && requestResponse.requestList.length) ? requestResponse.requestList : [],
            totalRequestCount: (requestResponse && requestResponse.totalRequests) ? requestResponse.totalRequests : null,
            showSortingLoader: false
        })
    }

    handleSearchFieldKeyPress = async (e) => {
        const { searchFields } = _.cloneDeep(this.state);

        if (e.key === "Enter") {
            this.searchRequestsHandler();
        }
    }

    searchRequestsHandler = async () => {
        const { searchFields, selectedHealthSystem } = _.cloneDeep(this.state);

        const filteredData = _.filter(searchFields, function(d) {
            return (d.isError && d.isError === true)
        })

        if (filteredData && filteredData.length) {
            return;
        }

        const page = 0
        const rowsPerPage = 100
        this.setState({
            requestList: [],
            totalRequestCount: null,
            showSortingLoader: true,
            page: page,
            rowsPerPage: rowsPerPage
        })

        const requestResponse = await fetchAllRequests(selectedHealthSystem.value, searchFields, page, rowsPerPage)

        this.setState({
            requestList: (requestResponse && requestResponse.requestList && requestResponse.requestList.length) ? requestResponse.requestList : [],
            totalRequestCount: (requestResponse && requestResponse.totalRequests) ? requestResponse.totalRequests : null,
            showSortingLoader: false
        })
    }

    fetchResponseGrid = (classes) => {
        const {
            requestList,
            totalRequestCount,
            rowsPerPage,
            page,
            selectedHealthSystem,
            showSortingLoader,
            searchFields
        } = _.cloneDeep(this.state);

        // const filteredData = _.filter(searchFields, function(d) {
        //     return ((d.value !== "" && d.value !== null) || d.isError === false)
        // })

        // const isFilterDataFill = (filteredData) && filteredData.length ? true : false

        return (
            <React.Fragment>
                <Grid container spacing={3} className="request-container">
                    <Grid item xs={12}>
                        <Paper className={classes.paper + ' category-paper-wrapper'}>
                            <Toolbar className="search-requests-wrapper">
                                <Grid container spacing={3}>
                                    {
                                        this.fetchSearchFieldsTemplate(classes)
                                    }
                                    <Grid item xs={12} md={12} style={{display: "flex", justifyContent: "flex-end"}}>
                                        <Button
                                            variant='outlined'
                                            color="primary"
                                            onClick={() => this.clearSearchFieldsHandler()}
                                            disabled={(showSortingLoader) ? true : false}
                                            title='Clear'
                                            style={{marginRight: "1rem", minWidth: '45px'}}
                                        >
                                            Clear 
                                            {/* <ClearIcon /> */}
                                        </Button>

                                        <Button
                                            variant='contained'
                                            color="primary"
                                            onClick={() => this.searchRequestsHandler()}
                                            disabled={(showSortingLoader) ? true : false}
                                            title='Search'
                                            style={{minWidth: '45px'}}
                                        >
                                            Search
                                            {/* <SearchIcon /> */}
                                        </Button>
                                    </Grid>
                                </Grid>
                                {/* <div className="category-information">
                                    <Typography variant='h6' className="category-label">
                                        Request Filtered By :
                                    </Typography>
                                    <Typography color='primary' variant='h6' className="category-name">
                                        {(selectedHealthSystem && selectedHealthSystem.label) ? selectedHealthSystem.label : null}
                                    </Typography>
                                </div> */}
                            </Toolbar>

                            <TableContainer className="request-table-list">
                                <Table className={classes.table} aria-label="simple table" size='small'>
                                    <TableHead className="request-table-list-header">
                                        <TableRow>
                                            <TableCell align="center">ROI Request ID</TableCell>
                                            <TableCell align="center">Vendor Request ID</TableCell>
                                            <TableCell align="center">Request ID</TableCell>
                                            <TableCell align="center">Updated Date</TableCell>
                                            <TableCell align="center">File Status</TableCell>
                                            <TableCell align="center">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>                                        

                                        {
                                            (requestList.length > 0
                                                ? requestList
                                                : []
                                            ).map((row, index) => (
                                                <TableRow key={row.id} id={row.id} className="request-table-row">
                                                    <TableCell align="center" className="request-table-column name capital-text">{row.requesterrefid}</TableCell>
                                                    <TableCell align="center" className="request-table-column name capital-text">{row.vendorrequestid}</TableCell>
                                                    <TableCell align="center" className="request-table-column name capital-text">{row.requestid}</TableCell>
                                                    <TableCell align="center" className="request-table-column name capital-text">{row.updatedat}</TableCell>
                                                    <TableCell align="center" className="request-table-column name capital-text">{this.fetchFileStatusTemplate(row.filestatus, classes)}</TableCell>
                                                    <TableCell align="center" className="request-table-column name capital-text">
                                                        <div className="action-btn-wrapper" style={{ justifyContent: 'center' }}>
                                                            <Button
                                                                variant='outlined'
                                                                color="primary"
                                                                onClick={() => this.showJobSummaryHandler(row)}
                                                                disabled={(showSortingLoader || row.showJobSummaryLoader) ? true : false}
                                                                title='Job Summary'
                                                            >
                                                                <VisibilityIcon />
                                                                {
                                                                    row.showJobSummaryLoader ? (
                                                                        <Box className='CustLodder' padding={1}>
                                                                            <CircularProgress size={20} />
                                                                        </Box>
                                                                    ) : null
                                                                }
                                                            </Button>

                                                            {/* <Button
                                                                variant='outlined'
                                                                onClick={() => this.RADocumentDownloadHandler(row, true)}
                                                                color="primary"
                                                                disabled={(row.showRADocumentDownloadLoader || showSortingLoader || !row.filepath) ? true : false}
                                                                title="Download RA Document"
                                                            >
                                                                <PictureAsPdfIcon />
                                                                {
                                                                    row.showRADocumentDownloadLoader ? (
                                                                        <Box className='CustLodder' padding={1}>
                                                                            <CircularProgress size={20} />
                                                                        </Box>
                                                                    ) : null
                                                                }
                                                            </Button> */}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }

                                        {
                                            (requestList.length === 0) ? (
                                                showSortingLoader ? (
                                                    <TableRow>
                                                        <TableCell colSpan={6} align='center'>
                                                            <Box padding={1}>
                                                                <Box className={classes.textAlignCenter}>
                                                                    <CircularProgress size={40} />
                                                                </Box>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                ) : (
                                                    <TableRow>
                                                        <TableCell
                                                            colSpan={6}
                                                            align='center'
                                                        >No Record Found</TableCell>
                                                    </TableRow>
                                                )                                                
                                            ) : null
                                        }
                                    </TableBody>

                                    {
                                        requestList.length > 0 ? (
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        rowsPerPageOptions={[100, 125, 150]}
                                                        colSpan={6}
                                                        count={totalRequestCount}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        SelectProps={{
                                                            inputProps: { 'aria-label': 'Records per page' },
                                                            native: true,
                                                        }}
                                                        onPageChange={this.handleChangePage}
                                                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                                                        ActionsComponent={TablePaginationActions}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        ) : null
                                    }

                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }

    fetchFileStatusTemplate = (filestatus, classes) => {
        const status = _.findKey(FILE_STATUS, function (f) { return f.includes(filestatus) })
        let className = ""

        switch (status) {
            case "Inqueue":
                className = "statusInqueue"
                break;
            case "Inprogress":
                className = "statusInprogress"
                break;
            case "Patient Not Found":
                className = "statusPatientNotFound"
                break;
            case "Multiple Patients Found":
                className = "statusMultiplePatientsFound"
                break;
            case "Encounter Not Found":
                className = "statusEncounterNotFound"
                break;
            case "Document Not Found":
                className = "statusDocumentNotFound"
                break;
            case "Processed":
                className = "statusProcessed"
                break;
            case "Failed":
                className = "statusFailed"
                break;
            case "FYI Flag Found":
                className = "statusFYIFlagFound"
                break;
            case "Patient Mismatch":
                className = "patientMismatch"
                break;
            default:
                className = "statusDefault"
                break;
        }
        
        return (
            <Chip
                size='small'
                label={status}
                className={classes[className]}
            />
        )
    };

    RADocumentDownloadHandler = async (row) => {
        let { requestList } = _.cloneDeep(this.state);
        let filterData = _.find(requestList, { id: row.id });
        if (filterData) {
            filterData.showRADocumentDownloadLoader = true
        }
        await this.setState({ requestList: requestList })
        const documentURL = await downloadRADocumentUsingSignedURL(row.requestid)
        if (documentURL) {
            window.open(documentURL, '_blank')
        }
        if (filterData) {
            filterData.showRADocumentDownloadLoader = false
        }
        await this.setState({ requestList: requestList })
    }

    showJobSummaryHandler = async (row) => {
        let { requestList } = _.cloneDeep(this.state);
        let filterData = _.find(requestList, { id: row.id });
        if (filterData) {
            filterData.showJobSummaryLoader = true
        }
        await this.setState({ requestList: requestList, jobSummaryPage: 0, jobSummaryRowsPerPage: 5 })
        const jobSummaryList = await fetchJobSummary(row.requestid, row.requesterrefid);

        if (filterData) {
            filterData.showJobSummaryLoader = false
        }
        await this.setState({
            requestList: requestList,
            showDialogModal: true,
            jobSummaryList: (jobSummaryList && jobSummaryList.length) ? jobSummaryList : [],
            totalJobSummaryCount: (jobSummaryList && jobSummaryList.length) ? jobSummaryList.length : null,
        })
    }

    handleChangePage = async (event, newPage) => {
        const { searchFields, selectedHealthSystem, rowsPerPage } = _.cloneDeep(this.state);
        await this.setState({
            page: newPage,
            showSortingLoader: true,
            requestList: []
        });

        const requestResponse = await fetchAllRequests(selectedHealthSystem.value, searchFields, newPage, rowsPerPage)

        await this.setState({
            requestList: (requestResponse && requestResponse.requestList && requestResponse.requestList.length) ? requestResponse.requestList : [],
            totalRequestCount: (requestResponse && requestResponse.totalRequests) ? requestResponse.totalRequests : null,
            showSortingLoader: false
        })
    };

    handleChangeRowsPerPage = async (event) => {
        const { searchFields, selectedHealthSystem } = _.cloneDeep(this.state);

        await this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
            showSortingLoader: true,
            requestList: []
        });

        const requestResponse = await fetchAllRequests(selectedHealthSystem.value, searchFields, 0, parseInt(event.target.value, 10))

        await this.setState({
            requestList: (requestResponse && requestResponse.requestList && requestResponse.requestList.length) ? requestResponse.requestList : [],
            totalRequestCount: (requestResponse && requestResponse.totalRequests) ? requestResponse.totalRequests : null,
            showSortingLoader: false
        })
    };

    fetchJobSummaryTemplate = (classes) => {
        const {
            jobSummaryList,
            totalJobSummaryCount,
            jobSummaryRowsPerPage,
            jobSummaryPage
        } = _.cloneDeep(this.state)
        return (
            <React.Fragment>
                <Grid container spacing={3} className="request-container">
                    <Grid item xs={12}>
                        <Paper className={classes.paper + ' category-paper-wrapper'}>
                            <TableContainer className="request-table-list">
                                <Table className={classes.table} aria-label="simple table" size='small'>
                                    <TableHead className="request-table-list-header">
                                        <TableRow>
                                            <TableCell align="left">ROI Request ID</TableCell>
                                            <TableCell align="left">Request ID</TableCell>
                                            <TableCell align="left">Error Response</TableCell>
                                            <TableCell align="left">Log Summary</TableCell>
                                            <TableCell align="left">Resource URL</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {
                                            (jobSummaryList.length > 0
                                                ? jobSummaryList.slice(jobSummaryPage * jobSummaryRowsPerPage, jobSummaryPage * jobSummaryRowsPerPage + jobSummaryRowsPerPage)
                                                : jobSummaryList
                                            ).map((row, index) => (
                                                <TableRow key={row.id} id={row.id} className="request-table-row">
                                                    <TableCell align="left" className="request-table-column name capital-text" title={row.requestid}>{row.requesterrefid}</TableCell>
                                                    <TableCell align="left" className="request-table-column name capital-text" title={row.requestid}>{row.requestid}</TableCell>
                                                    <TableCell align="left" className="request-table-column name capital-text" title={row.errorresponse}>{row.errorresponse}</TableCell>
                                                    <TableCell align="left" className="request-table-column name capital-text" title={row.logsummary}>{row.logsummary}</TableCell>
                                                    <TableCell align="left" className="request-table-column name capital-text" title={row.resourceurl}>{row.resourceurl}</TableCell>
                                                </TableRow>
                                            ))
                                        }

                                        {
                                            (jobSummaryList.length === 0) ? (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={5}
                                                        align='center'
                                                    >No Record Found</TableCell>
                                                </TableRow>
                                            ) : null
                                        }
                                    </TableBody>

                                    {
                                        jobSummaryList.length > 0 ? (
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        rowsPerPageOptions={[5, 10, 25]}
                                                        colSpan={5}
                                                        count={totalJobSummaryCount}
                                                        rowsPerPage={jobSummaryRowsPerPage}
                                                        page={jobSummaryPage}
                                                        SelectProps={{
                                                            inputProps: { 'aria-label': 'rows per page' },
                                                            native: true,
                                                        }}
                                                        onPageChange={this.handleJobSummaryChangePage}
                                                        onRowsPerPageChange={this.handleJobSummaryChangeRowsPerPage}
                                                        ActionsComponent={TablePaginationActions}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        ) : null
                                    }

                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }

    handleJobSummaryChangePage = (event, newPage) => {
        this.setState({
            jobSummaryPage: newPage
        });
    };

    handleJobSummaryChangeRowsPerPage = (event) => {
        this.setState({
            jobSummaryRowsPerPage: parseInt(event.target.value, 10),
            jobSummaryPage: 0
        });
    };

    handleJobSummaryCancelEvent = async () => {
        await this.setState({
            showDialogModal: false
        })
    }

    render() {
        const { classes } = this.props;
        const {
            healthSystemList,
            selectedHealthSystem,
            showDialogModal,
            showLoader
        } = _.cloneDeep(this.state)

        return (
            <Container className='main-container'>
                {
                    showLoader ? (
                        <div className='CustLodder circular-loader'>
                            <Box className='CustLodder' padding={1}>
                                <Box className={classes.textAlignCenter}>
                                    <CircularProgress size={40} />
                                </Box>
                            </Box>
                        </div>
                    ) : null
                }

                {
                    showDialogModal ? (
                        <DialogModal
                            isOpen={true}
                            modalTitle='Job Summary Details'
                            modalContent={this.fetchJobSummaryTemplate(classes)}
                            handleCancelEvent={this.handleJobSummaryCancelEvent}
                            modalCancelButtonText='Cancel'
                            showCloseIcon={true}
                            closeIconEvent={this.handleJobSummaryCancelEvent}
                            maxWidth="lg"
                        />
                    ) : null
                }
                <Grid container className='all-resouces-list-wrapper' spacing={3}>
                    <Grid item xs={12}>
                        <Paper className='respurce-list-paper'>
                            <Grid container spacing={2}>
                                <Grid item xs={8} md={4}>
                                    <Autocomplete
                                        options={healthSystemList}
                                        value={selectedHealthSystem}
                                        size='small'
                                        getOptionLabel={({ label }) => label}
                                        getOptionSelected={(option, value) => option.value === value.value}
                                        disableClearable={true}
                                        blurOnSelect
                                        onChange={(event, value) => this.onHealthSystemChangeHandler(value)}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Select Health System" variant="outlined" />
                                        )}
                                        className="autocomplete-field"
                                    />
                                </Grid>

                                <Grid item xs={4} md={8} className={classes.landingPageButtonWarpper}>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={() => this.refreshChartButtonClickHandler()}
                                        className='show-req-btn'
                                        title="Click to refresh data"
                                    >
                                        Refresh Chart
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid >

                    <Grid item xs={12}>
                        <Paper>
                            <div id="chartdiv" style={{ width: "100%", height: "400px", paddingTop: "1rem", paddingBottom: "1rem" }}></div>
                        </Paper>
                    </Grid>

                    {/* <Grid item xs={12}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography className={classes.heading}>Detail Summary</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                                    sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid> */}

                    {
                        !showLoader ? (
                            <Grid item xs={12} md={12}>
                                {this.fetchResponseGrid(classes)}
                            </Grid>
                        ) : null
                    }
                    
                </Grid >
            </Container >
        )
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(style)(Dashboard)));